$(document).foundation();
// SCROLL SMOOTH
$('a[href^="#"]').click(function() {
    var the_id = $(this).attr("href");

    $('html, body').animate({
        scrollTop: $(the_id).offset().top
    }, 'slow');
    return false;
});
// END SCROLL SMOOTH



/*burger*/
$('.material--burger').on('click', function() {
        $(this).toggleClass('material--arrow');
});




$(".navigation--button, .material--burger").click(function() {
        $(".menu").toggleClass("menu_visible");
        return false;
    });
/*burger*/



$('.bloc_vert').on('click', function() {
		$('.bloc_open').toggleClass('bloc_open');
        $(this).toggleClass('bloc_open');
});




$(document).ready(function() {


 $('.photos').slick({
        dots: false,
        focusOnSelect: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
        variableWidth: true,
        centerMode: false,
        centerPadding: '0',
        slidesToShow: 3,
        responsive: [{
            breakpoint: 1080,
            settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '50px',
                slidesToShow: 1,
                autoplay: false
            }
        }, {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '20px',
                slidesToShow: 1,
                autoplay: false
            }
        }]
    });


 $('.photos2').slick({
        dots: false,
        focusOnSelect: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
        variableWidth: true,
        centerMode: false,
        centerPadding: '0',
        slidesToShow: 3,
        responsive: [{
            breakpoint: 1080,
            settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '50px',
                slidesToShow: 1,
                autoplay: false
            }
        }, {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '20px',
                slidesToShow: 1,
                autoplay: false
            }
        }]
    });


$('.slick_testimonials').slick({
        dots: false,
        focusOnSelect: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        // adaptiveHeight: true,
        // variableWidth: true,
        // centerMode: true,
        // centerPadding: '0'
    });

});




        
/*map*/
function initialize() {
    var mapProp = {
        center: new google.maps.LatLng(50.286174, 5.481812),
        zoom: 14,
        scrollwheel: false,
        mapTypeControl: false,
        streetViewControl: false,
        panControl: false,
        styles: [{
            stylers: [{
                "gamma": 1
            }, {
                "lightness": 50
            }, {
                "hue": "#382872"
            }, {
                "saturation": 20
            }]
        }],


        mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    var map = new google.maps.Map(document.getElementById("googleMap1"), mapProp);


    //création du marqueur
    var marqueur = new google.maps.Marker({

        position: new google.maps.LatLng(50.286174, 5.481812),
        icon: "squelettes/dist/assets/img/pin.png",
        map: map
    });

}


/*map 2*/
  
google.maps.event.addDomListener(window, 'load', initialize);
/*map 2*/

